// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  visit(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    Turbo.visit(target.dataset.link)
  }
}
