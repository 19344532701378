import consumer from "../channels/consumer"
import { Turbo } from "../turbo"

import CacheController from "./cache_controller"
import EULAController from "./eula_controller"
import InvitationsController from "./invitations_controller"
import LinkerController from "./linker_controller"
import LocalesController from "./locales"
import ManageProductsController from "./manage_products_controller"
import WebauthnRegisterController from "./webauthn_register_controller"
import TwoFactorController from "./two_factor_controller"
import UsersController from "./users_controller"
import PropertiesController from "./properties_controller"
import PasskeysController from "./passkeys_controller"
import OrganizationsController from "./organizations_controller"
import {
  AccordionController,
  AutoSearchController,
  CalendarDatePicker,
  CheckboxFilterController,
  ClassObserverController,
  ColorSchemeController,
  CustomFiltersController,
  DateManagerController,
  DatetimepickerController,
  FlashController,
  HamburgerController,
  InfiniteScrollController,
  ModalController,
  NomadixStreamController,
  PillController,
  PopoverController,
  PopupController,
  TooltipController,
  SelectController,
  SidebarController,
  TableSortController,
  Timeago,
  ToggleHiddenController,
  buildApplication,
  TabsController,
  ChartController,
  SessionTimeoutController,
} from "../../../engines/nc/app/javascript/nc/controllers"

import {
  FailoverController,
  GatewayCsvController,
  StatFilterController,
  ViewSwitchController,
  FirmwareUpgradesModalController,
  GatewayKeywordController,
  PillsObserverController,
  HeaderMinimizerController,
  ModalBaseController,
  ModalCloserController,
  SnmpFormController,
  FleetController,
  XmlFormController,
  HeatmapController,
  GatewayConnectivityPanel,
  GatewayBackupController
} from "../../../engines/network/app/javascript/network/controllers"

import PMSSettingsController from "../../../engines/pms/app/javascript/pms/controllers/pms_settings_controller"
import PMSRoomsFilterController from "../../../engines/pms/app/javascript/pms/controllers/pms_rooms_filter_controller"

const application = buildApplication()

application.register("accordion", AccordionController)
application.register("cache", CacheController)
application.register("color-scheme", ColorSchemeController)
application.register("eula", EULAController)
application.register("properties", PropertiesController)
application.register("failover", FailoverController)
application.register("flash", FlashController)
application.register("hamburger", HamburgerController)
application.register("modal", ModalController)
application.register("datetimepicker", DatetimepickerController)
application.register("header-minimizer", HeaderMinimizerController)
application.register("infinite-scroll", InfiniteScrollController)
application.register("invitations", InvitationsController)
application.register("linker", LinkerController)
application.register("locales", LocalesController)
application.register("manage-products", ManageProductsController)
application.register("gateway-csv", GatewayCsvController)
application.register("auto-search", AutoSearchController)
application.register("gateway-keyword", GatewayKeywordController)
application.register("pills-observer", PillsObserverController)
application.register("stat-filter", StatFilterController)
application.register("pms-rooms-filter", PMSRoomsFilterController)
application.register("timeago", Timeago)
application.register("toggle-hidden", ToggleHiddenController)
application.register("nomadix-stream", NomadixStreamController)
application.register("two-factor", TwoFactorController)
application.register("users", UsersController)
application.register("view-switch", ViewSwitchController)
application.register("firmware-upgrades-modal", FirmwareUpgradesModalController)
application.register("pms-settings", PMSSettingsController)
application.register("table-sort", TableSortController)
application.register("modal-base", ModalBaseController)
application.register("modal-closer", ModalCloserController)
application.register("snmp-form", SnmpFormController)
application.register("xml-form", XmlFormController)
application.register("select", SelectController)
application.register("webauthn-register", WebauthnRegisterController)
application.register("passkeys", PasskeysController)
application.register("custom-filters", CustomFiltersController)
application.register("checkbox-filter", CheckboxFilterController)
application.register("pill", PillController)
application.register("fleet", FleetController)
application.register("sidebar", SidebarController)
application.register("heatmap", HeatmapController)
application.register("chart", ChartController)
application.register("class-observer", ClassObserverController)
application.register("date-manager", DateManagerController)
application.register("popover", PopoverController)
application.register("popup", PopupController)
application.register("tooltip", TooltipController)
application.register("calendar-date-picker", CalendarDatePicker)
application.register("gateway-connectivity-panel", GatewayConnectivityPanel)
application.register("gateway-backup", GatewayBackupController)
application.register("tabs", TabsController)
application.register("organizations", OrganizationsController)
application.register(
  "session-timeout",
  class extends SessionTimeoutController {
    initialize() {
      super.initialize()
      this.turbo = Turbo
      this.actionCableConsumer = consumer
    }
  }
)
